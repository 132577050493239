import axios from "axios";
import Swal from "sweetalert2";

// import FlashMessage from '@smartweb/vue-flash-message';

export default class ApiClass {
  static fm;
  static fms(FlashMessage) {
    this.fm = FlashMessage;
  }

  static InrToUsdt = "https://demo-node.abundancetacc.com/get-currency-price";



  // static nodeUrl = "https://node.abundancetacc.com/";
  // static baseUrl = "https://backend.abundancetacc.com/api/";
  // static nodeImageUrl = "https://node.abundancetacc.com/";

  
  // LOCAL LINK
  //  static nodeUrl = "http://192.168.11.76:4020/";
  //  static baseUrl = "http://192.168.11.76:8000/api/";
  //  static nodeImageUrl = this.nodeUrl;

   // DEMO LINK
  //  static nodeUrl = "http://13.235.132.240/";
  //  static baseUrl = "http://13.235.132.240/api/";
  // static nodeImageUrl = this.nodeUrl;

     // DEMO LINK
   static nodeUrl = "https://demo-node.abundancetacc.com/";
   static baseUrl = "https://demo-backend.abundancetacc.com/api/";
  static nodeImageUrl = this.nodeUrl;


  //******************************** Post api ********************************************//

  static postRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    return axios
      .post(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }

        if (error.response.status == 429) {
          // alert(error.response.data.message);
          Swal.fire({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
          });
        }
      });
  }

  //******************************** Get api ********************************************//

  static getRequest(apiUrl, isToken = true, headers = null, params = null) {
    return axios
      .get(this.baseUrl + apiUrl, this.config(isToken, headers, params))
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }

  //********************************* Update api *********************************************** */

  //******************** if form data with image ************************* */

  static updateFormRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    baseParam = { _method: "PUT" };
    if (params != null) {
      // var baseParam = $.extend(params, baseParam)
      var baseParam = { ...params, ...baseParam };
    }
    return axios
      .post(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, baseParam)
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }
  //******************** form data in json format ************************* */

  static updateRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    return axios
      .put(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }

  //************************************ Delete api **************************************************** */

  static deleteRequest(apiUrl, isToken = true, headers = null, params = null) {
    return axios
      .delete(this.baseUrl + apiUrl, this.config(isToken, headers, params))
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }

  //******************************** Configrations of header and parameters ******************************** */

  static config(isToken = true, headers = null, parameters = null) {
    var defaultHeaders = {
      Accept: "application/json",
    };
    var merge;
    merge = Object.assign(defaultHeaders, headers);

    if (isToken) {
      var x = JSON.parse(localStorage.getItem("user_info"));
      var token = { Authorization: "Bearer " + x.token };
      // var x =localStorage.getItem("user");
      console.log( x);
      // var merge = $.extend(defaultHeaders, token)

      merge = Object.assign(defaultHeaders, token);
    }
    // var merge = $.extend(defaultHeaders, headers)

    return {
      headers: merge,
      params: parameters,
    };
  }

  //********************************** if the unautherntication Error..... *************************************** */

  static unauthenticateRedirect() {
    localStorage.clear();
    // window.events.$emit('flash', { 'message': 'info' });
    location.replace("/login");
    // console.log(FlashMessage);
    this.fms.show({
      status: "error",
      title: "UnAuthentication",
      message: "You are currently blocked by the admin or try to login again",
    });
  }

  //**********************************  node Api  *************************************** */

  static getNodeRequest(apiUrl, isToken = true, headers = null, params = null) {
    return axios
      .get(this.nodeUrl + apiUrl, this.config(isToken, headers, params))
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }

  static postNodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    return axios
      .post(
        this.nodeUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        // console.log({er : error});
        if (error.response.status == 401) {
          // this.unauthenticateRedirect();
        }
      });
  }
  static updatenodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    return axios
      .put(
        this.nodeUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }

  static getConversion() {
    return axios
      .get(this.InrToUsdt)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.unauthenticateRedirect();
        }
      });
  }
}
