import store from '@/state/store'

export default [{
    path: '/',
    name: 'crypto-dashboard',
    meta: { authRequired: true },
    component: () =>
        import('./views/dashboards/crypto/index')
},

{
    path: '/login',
    name: 'login',
    component: () =>
        import('./views/account/login'),
    meta: {
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({ name: 'default' })
            } else {
                // Continue to the login page
                next()
            }
        },
    },
},



{
    path: "/ico/round",
    name: "roundCreate",
    meta: { authRequired: true },
    component: () => import("./views/airdrop/Round_Create.vue"),
},
{
    path: "/ico/balances",
    name: "BirakeBalances",
    meta: { authRequired: true },
    component: () => import("./views/airdrop/Birake_balances.vue"),
},
{
    path: "/ico/claim_history",
    name: "ClaimHistory",
    meta: { authRequired: true },
    component: () => import("./views/airdrop/ClaimHistory.vue"),
},

//stacking-routes... 

{
    path: "/staking/list",
    name: "staking List",
    meta: { authRequired: true },
    component: () => import("./views/staking/list.vue"),
  },
  {
    path: "/staking/create",
    name: "staking Create",
    meta: { authRequired: true },
    component: () => import("./views/staking/create"),
  },
  {
    path: "/user_stake/list/:slug",
    name: "User Stake List",
    meta: { authRequired: true ,slug:true},
    component: () =>
      import("./views/staking/user_stake_list.vue"),
  },
  
// {
//     path: "/staking/list",
//     name: "staking List",
//     meta: { authRequired: true },
//     component: () => import("./views/staking/list"),
// },
// {
//     path: "/staking/create",
//     name: "staking Create",
//     meta: { authRequired: true },
//     component: () => import("./views/staking/create"),
// },
// {
//     path: "/user_stake/list",
//     name: "User Stake List",
//     meta: { authRequired: true },
//     component: () =>
//         import("./views/staking/user_stake_list.vue"),
// },
// {
//     path: '/register',
//     name: 'Register',
//     component: () =>
//         import ('./views/account/register'),
//     meta: {
//         beforeResolve(routeTo, routeFrom, next) {
//             // If the user is already logged in
//             if (store.getters['auth/loggedIn']) {
//                 // Redirect to the home page instead
//                 next({ name: 'default' })
//             } else {
//                 // Continue to the login page
//                 next()
//             }
//         },
//     },
// },
{
    path: '/forgot-password',
    name: 'Forgot password',
    component: () =>
        import('./views/account/forgot-password'),
    meta: {
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({ name: 'default' })
            } else {
                // Continue to the login page
                next()
            }
        },
    },
},
{
    path: '/logout',
    name: 'logout',
    meta: {
        authRequired: true,
        beforeResolve(routeTo, routeFrom, next) {
            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                store.dispatch('auth/logOut')
            } else {
                store.dispatch('authfack/logout')
            }
            const authRequiredOnPreviousRoute = routeFrom.matched.some(
                (route) => route.push('/login')
            )
            // Navigate back to previous page, or home as a fallback

            next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
        },
    },
},
{
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
},
// Redirect any unmatched routes to the 404 page. This may
// require some server configuration to work in production:
// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
{
    path: '*',
    redirect: '404',
},
// {
//     path: '/dashboard/saas',
//     name: 'saas-dashboard',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/dashboards/saas/index')
// },
{
    path: '/',
    name: 'default',
    meta: {
        authRequired: true,
    },
    component: () =>
        // import('./views/dashboards/default'),
        import('./views/dashboards/crypto/index'),
},
// {
//     path: '/dashboard/blog',
//     name: 'blog-dashboard',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/dashboards/blog/index')
// },
// {
//     path: '/calendar',
//     name: 'Calendar',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/calendar/calendar')
// },
// {
//     path: '/chat',
//     name: 'chat',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/chat/index')
// },
// {
//     path: '/apps/file-manager',
//     name: 'file-manager',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/file-manager/index')
// },
// {
//     path: '/ecommerce/products',
//     name: 'Products',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/products')
// },
// {
//     path: '/ecommerce/product-detail/:id',
//     name: 'Product Detail',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/product-detail')
// },
// {
//     path: '/ecommerce/orders',
//     name: 'Orders',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/orders')
// },
// {
//     path: '/ecommerce/customers',
//     name: 'Customers',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/customers')
// },
// {
//     path: '/ecommerce/cart',
//     name: 'Cart',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/cart')
// },
// {
//     path: '/ecommerce/checkout',
//     name: 'Checkout',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/checkout')
// },
// {
//     path: '/ecommerce/shops',
//     name: 'Shops',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/shops')
// },
// {
//     path: '/ecommerce/add-product',
//     name: 'Add Product',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ecommerce/add-product')
// },
// {
//     path: '/blog/list',
//     name: 'blog-list',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/blog/list')
// },
// {
//     path: '/blog/grid',
//     name: 'blog-grid',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/blog/grid')
// },
// {
//     path: '/blog/detail',
//     name: 'blog-detail',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/blog/detail')
// },
{
    path: '/crypto/wallet',
    name: 'Wallet',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/wallet/index')
},
// {
//     path: '/setting/company',
//     name: 'setting',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/index')
// },
// {
//     path: '/setting/email',
//     name: 'email',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/emailsetting')
// },
// {
//     path: '/setting/sms',
//     name: 'sms',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/smssetting')
// },
// {
//     path: '/setting/notification',
//     name: 'notification',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/notificationsetting')
// },
// {
//     path: '/setting/payment',
//     name: 'payment',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/paymentsetting')
// },
// {
//     path: '/setting/recapta',
//     name: 'recapta',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/setting/recaptasetting')
// },
// {
//     path: '/crypto/buy-sell',
//     name: 'Buy/sell',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/crypto/buysell/buysellpage')
// },
// {
//     path: '/crypto/pump_dump',
//     name: 'Pump-Dump',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/crypto/buysell/pump_dump')
// },
// {
//     path: '/crypto/exchange',
//     name: 'Exchange',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/exchange/index')
// },
{
    path: '/crypto/lending',
    name: 'Lending',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/lending/index.vue')
},
{
    path: '/crypto/orders',
    name: 'crypto-orders',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/orders/index')
},
{
    path: '/crypto/orders/:id',
    name: 'crypto-userorder',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/userorder/index')
},
/*  {
     path: '/crypto/kyc-application',
     name: 'kyc-application',
     meta: { authRequired: true },
     component: () =>
         import('./views/crypto/kycapplication/index')
 }, */
{
    path: '/kyc/completed',
    name: 'Kyc Completed',
    meta: { authRequired: true },
    component: () =>
        import('./views/kyc/completed')
},
{
    path: '/kyc/rejected',
    name: 'Kyc Rejected ',
    meta: { authRequired: true },
    component: () =>
        import('./views/kyc/rejected')
},
{
    path: '/kyc/pending',
    name: 'kyc Pending',
    meta: { authRequired: true },
    component: () =>
        import('./views/kyc/pending')
},
// {
//     path: '/crypto/ico-landing',
//     name: 'Ico-landing',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/ico-landing')
// },
{
    path: '/bank/completed',
    name: 'Bank Completed',
    meta: { authRequired: true },
    component: () =>
        import ('./views/bank/completed')
},
{
    path: '/bank/rejected',
    name: 'Bank Rejected ',
    meta: { authRequired: true },
    component: () =>
        import ('./views/bank/rejected')
},
{
    path: '/bank/pending',
    name: 'Bank Pending',
    meta: { authRequired: true },
    component: () =>
        import ('./views/bank/pending')
},

// {
//     path: '/upi/completed',
//     name: 'Upi Completed',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/upi/completed')
// },
// {
//     path: '/upi/rejected',
//     name: 'Upi Rejected ',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/upi/rejected')
// },
// {
//     path: '/upi/pending',
//     name: 'Upi Pending',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/upi/pending')
// },

// {
//     path: '/invoices/detail',
//     name: 'Invoice Detail',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/invoices/detail')
// },
// {
//     path: '/invoices/list',
//     name: 'Invoice List',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/invoices/list')
// },
// {
//     path: '/ui/alerts',
//     name: 'Alerts',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('./views/ui/alerts')
// },
// {
//     path: '/ui/buttons',
//     name: 'Buttons',
//     meta: {
//         authRequired: true
//     },
//     component: () =>
//         import ('./views/ui/buttons')
// },
// {
//     path: '/ui/cards',
//     name: 'Cards',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/cards')
// },
// {
//     path: '/ui/carousel',
//     name: 'Carousel',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/carousel')
// },
// {
//     path: '/ui/dropdowns',
//     name: 'Dropdowns',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/dropdowns')
// },
// {
//     path: '/ui/grid',
//     name: 'Grid',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/grid')
// },
// {
//     path: '/ui/images',
//     name: 'Images',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/images')
// },
// {
//     path: '/ui/modals',
//     name: 'Modals',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/modals')
// },
// {
//     path: '/ui/drawer',
//     name: 'Drawer',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/drawer')
// },
// {
//     path: '/ui/rangeslider',
//     name: 'Rangeslider',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/rangeslider')
// },
// {
//     path: '/ui/progressbars',
//     name: 'Progressbars',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/progressbars')
// },
// {
//     path: '/ui/sweet-alert',
//     name: 'Sweet-alert',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/sweet-alert')
// },
{
    path: '/ui/tabs-accordions',
    name: 'Tabs-accordions',
    meta: { authRequired: true },
    component: () =>
        import('./views/ui/tabs-accordions')
},
// {
//     path: '/ui/typography',
//     name: 'Typography',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/typography')
// },
// {
//     path: '/ui/video',
//     name: 'Video',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/video')
// },
// {
//     path: '/ui/general',
//     name: 'General',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/general')
// },
// {
//     path: '/ui/colors',
//     name: 'Colors',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/colors')
// },
// {
//     path: '/ui/lightbox',
//     name: 'Lightbox',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/lightbox')
// },
// {
//     path: '/ui/image-cropper',
//     name: 'Image Cropper',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/ui/cropper')
// },
// {
//     path: '/projects/grid',
//     name: 'Projects Grid',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/projects/projects-grid')
// },
// {
//     path: '/projects/list',
//     name: 'Projects List',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/projects/projects-list')
// },
// {
//     path: '/projects/overview',
//     name: 'Project Overview',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/projects/overview')
// },
// {
//     path: '/projects/create',
//     name: 'Create New',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/projects/create')
// },
// {
//     path: '/contacts/grid',
//     name: 'User Grid',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/contacts/contacts-grid')
// },
{
    path: '/users/list',
    name: 'User List',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/contacts-list')
},
{
    path: '/sub-admin/list',
    name: 'Sub Admin List',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/sub-admin-list')
},

{
    path: '/users/addCrypto',
    name: 'Add User Crypto',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/add-user-crypto.vue')
},

// {
//     path: '/users/add',
//     name: 'Add User',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/contacts/add-user')
// },
{
    path: '/:type/add',
    name: 'Add User',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/add-user')
},
{
    path: '/users/list/:id',
    name: 'UserList',
    meta: { authRequired: true },
    component: () =>
        import('./views/dashboards/crypto/UserList')
},
// {
//     path: '/crypto/wallettransactions/',
//     name: 'WalletTransactions',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/crypto/WalletTransactions/index')
// },
// {
//     path: '/crypto/wallettransactions/:id',
//     name: 'UserList',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/crypto/UserTransactions/index')
// },
{
    path: '/crypto',
    name: 'Crypto',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/Crypto')
},
{
    path: '/crypto/:id',
    name: 'UpdateCrypto',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/Cryptoupdate')
},
// {
//     path: '/contacts/profile',
//     name: 'Profile',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/contacts/contacts-profile')
// },
// {
//     path: '/charts/apex',
//     name: 'Apex chart',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/charts/apex')
// },
// {
//     path: '/charts/chartjs',
//     name: 'Chartjs chart',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/charts/chartjs/index')
// },
// {
//     path: '/charts/chartist',
//     name: 'Chartist chart',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/charts/chartist')
// },
// {
//     path: '/charts/echart',
//     name: 'Echart chart',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/charts/echart/index')
// },
// {
//     path: '/icons/boxicons',
//     name: 'Boxicons Icon',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/icons/boxicons')
// },
// {
//     path: '/icons/materialdesign',
//     name: 'Materialdesign Icon',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/icons/materialdesign')
// },
// {
//     path: '/icons/dripicons',
//     name: 'Dripicons Icon',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/icons/dripicons')
// },
// {
//     path: '/icons/fontawesome',
//     name: 'Fontawesome Icon',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/icons/fontawesome')
// },
// {
//     path: '/maps/google',
//     name: 'Google Maps',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/maps/google')
// },
// {
//     path: '/maps/leaflet',
//     name: 'Leaflet Maps',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/maps/leaflet/index')
// },
// {
//     path: '/tables/basic',
//     name: 'Basic Tables',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/tables/basictable')
// },
// {
//     path: '/tables/advanced',
//     name: 'Advanced Tables',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/tables/advancedtable')
// },
// {
//     path: '/form/advanced',
//     name: 'Form Advanced',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/advanced')
// },
// {
//     path: '/form/elements',
//     name: 'Form Elements',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/elements')
// },
// {
//     path: '/form/layouts',
//     name: 'Form Layouts',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/layouts')
// },
// {
//     path: '/form/editor',
//     name: 'Form Editors',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/editors')
// },
// {
//     path: '/form/uploads',
//     name: 'File Uploads',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/uploads')
// },
// {
//     path: '/form/validation',
//     name: 'Form Validation',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/validation')
// },
// {
//     path: '/form/wizard',
//     name: 'Form Wizard',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/wizard')
// },
// {
//     path: '/form/repeater',
//     name: 'Form Repeater',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/repeater')
// },
// {
//     path: '/form/mask',
//     name: 'Form Mask',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/forms/mask')
// },
// {
//     path: '/pages/starter',
//     name: 'Starter',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/starter')
// },
// {
//     path: '/pages/maintenance',
//     name: 'Maintenance',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/maintenance')
// },
// {
//     path: '/pages/coming-soon',
//     name: 'coming-soon',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/coming-soon')
// },
// {
//     path: '/pages/timeline',
//     name: 'Timeline',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/timeline')
// },
// {
//     path: '/pages/faqs',
//     name: 'FAQs',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/faqs')
// },
// {
//     path: '/pages/pricing',
//     name: 'Pricing',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/utility/pricing')
// },
{
    path: '/pages/404',
    name: 'Error-404',
    meta: { authRequired: true },
    component: () =>
        import('./views/utility/404')
},
{
    path: '/pages/500',
    name: 'Error-500',
    meta: { authRequired: true },
    component: () =>
        import('./views/utility/500')
},
// {
//     path: '/email/inbox',
//     name: 'Inbox',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/email/inbox')
// },
// {
//     path: '/email/reademail/:id',
//     name: 'Read Email',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/email/reademail')
// },
// {
//     path: '/email/templates/basic',
//     name: 'Email template basic',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/email/templates/basic')
// },
// {
//     path: '/email/templates/billing',
//     name: 'Email template billing',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/email/templates/billing')
// },
// {
//     path: '/email/templates/alert',
//     name: 'Email template alert',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/email/templates/alert')
// },
// {
//     path: '/tasks/list',
//     name: 'Task list',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/tasks/task-list')
// },
// {
//     path: '/tasks/kanban',
//     name: 'Kanbanboard',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/tasks/kanbanboard')
// },
// {
//     path: '/tasks/create',
//     name: 'Create Task',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/tasks/task-create')
// },
// {
//     path: '/auth/login-1',
//     name: 'Login sample',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/login-sample')
// },
// {
//     path: '/auth/login-2',
//     name: 'Login-2-sample',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/login-2')
// },
// {
//     path: '/auth/register-1',
//     name: 'Register sample',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/register-sample')
// },
// {
//     path: '/auth/register-2',
//     name: 'Register-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/register-2')
// },
// {
//     path: '/auth/recoverpwd',
//     name: 'Recover pwd',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/recoverpw-sample')
// },
// {
//     path: '/auth/recoverpwd-2',
//     name: 'Recover pwd-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/recoverpwd-2')
// },
// {
//     path: '/auth/lock-screen',
//     name: 'Lock screen',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/lockscreen')
// },
// {
//     path: '/auth/lock-screen-2',
//     name: 'Lock screen-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/lockscreen-2')
// },
// {
//     path: '/auth/confirm-mail',
//     name: 'confirm-mail',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/confirm-mail')
// },
// {
//     path: '/auth/confirm-mail-2',
//     name: 'confirm-mail-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/confirm-mail-2')
// },
// {
//     path: '/auth/email-verification',
//     name: 'email-verification',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/email-verification')
// },
// {
//     path: '/auth/email-verification-2',
//     name: 'email-verification-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/email-verification-2')
// },
// {
//     path: '/auth/two-step-verification',
//     name: 'two-step-verification',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/two-step-verification')
// },
// {
//     path: '/auth/two-step-verification-2',
//     name: 'two-step-verification-2',
//     meta: { authRequired: true },
//     component: () =>
//         import ('./views/sample-pages/two-step-verification-2')
// },
{
    path: '/template/list',
    name: 'Template List',
    meta: { authRequired: true },
    component: () =>
        import('./views/template/list')
},
{
    path: '/template/create',
    name: 'Template Create',
    meta: { authRequired: true },
    component: () =>
        import('./views/template/create')
},
{
    path: '/notification/list',
    name: 'Notification List',
    meta: { authRequired: true },
    component: () =>
        import('./views/notification/list')
},
{
    path: '/notification/create',
    name: 'Notification Create',
    meta: { authRequired: true },
    component: () =>
        import('./views/notification/create')
},

{
    path: '/report/wallet',
    name: 'ReportWallet',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/report/Wallet')
},

{
    path: '/report/fee',
    name: 'ReportFees',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/report/Fee')
},

{
    path: '/report/client',
    name: 'ReportClient',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/report/Client')
},
{
    path: '/report/deposit',
    name: 'ReportClient',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/report/Deposit')
},
// {
//     path: '/report/withdrawal',
//     name: 'ReportClient',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/crypto/report/Withdrawal')
// },

{
    path: '/list_crypto/listedcoin',
    name: 'ListedCoin',
    meta: { authRequired: true },
    component: () =>
        import('./views/list_crypto/ListedCoin')
},

{
    path: '/list_crypto/updatecoin/:id',
    name: 'UpdateCoin',
    meta: { authRequired: true },
    component: () =>
        import('./views/list_crypto/UpdateCoin')
},

{
    path: '/list_crypto/addcoin',
    name: 'AddCoin',
    meta: { authRequired: true },
    component: () =>
        import('./views/list_crypto/AddCoin')
},
{
    path: '/currency/currencylist',
    name: 'Currency List',
    meta: { authRequired: true },
    component: () =>
        import('./views/currencies/Currency_list')
},

{
    path: '/page/list',
    name: 'PagesList',
    meta: { authRequired: true },
    component: () =>
        import('./views/page/list')
},


{
    path: '/page/show',
    name: 'PageShow',
    meta: { authRequired: true },
    component: () =>
        import('./views/page/show')
},

{
    path: '/page/update',
    name: 'PageEdit',
    meta: { authRequired: true },
    component: () =>
        import('./views/page/update')
},

// {
//     path: '/liquidity/list',
//     name: 'LiquidityList',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/liquidity/list')
// },

// {
//     path: '/liquidity/create',
//     name: 'LiquidityCreate',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/liquidity/add')
// },

// {
//     path: '/liquidity/edit/:symbol',
//     name: 'LiquidityUpdate',
//     meta: { authRequired: true },
//     component: () =>
//         import('./views/liquidity/edit')
// },

/// for banner 
{
    path: '/banner/list',
    name: 'BannerList',
    meta: { authRequired: true },
    component: () =>
        import('./views/banner/list')
},
{
    path: '/banner/create',
    name: 'BannerCreate',
    meta: { authRequired: true },
    component: () =>
        import('./views/banner/create')
},
{
    path: '/vendor/list',
    name: 'VendorList',
    meta: { authRequired: true },
    component: () =>
        import('./views/coinlister/list')
},
// Broker Module
{
    path: '/broker/list',
    name: 'BrokerList',
    meta: { authRequired: true },
    component: () =>
        import('./views/broker/list')
},
// Forex Transfer Module
{
    path: '/forextransfer/list',
    name: 'ForexTransferList',
    meta: { authRequired: true },
    component: () =>
        import('./views/forextransfer/list')
},
//for support

{
    path: '/support/category',
    name: 'SupportCategory',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/support-category')
},
{
    path: '/support/view-ticket/:slug',
    name: 'View Tickets',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/view-ticket')
},
{
    path: '/support/ticket-list',
    name: 'Ticket List',
    meta: { authRequired: true },
    component: () =>
        import('./views/contacts/ticket-list')

},
{
    path: '/request/tokens',
    name: 'Requested Tokens',
    meta: { authRequired: true },
    component: () =>
        import('./views/request_tokens/list')

},

{
    path: '/no-commission/list',
    name: 'No Commission List',
    meta: { authRequired: true },
    component: () =>
        import('./views/no_commission/list')
},
{
    path: '/no-commission/create',
    name: 'No Commission Create',
    meta: { authRequired: true },
    component: () =>
        import('./views/no_commission/add')
},
{
    path: '/settings',
    name: 'Settings',
    meta: { authRequired: true },
    component: () =>
        import('./views/settings')
},

// for p2p report 

{
    path: '/p2p/allorders',
    name: 'P2P all Orders ',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/p2p/all-orders')
},
{
    path: '/p2p/chat-list',
    name: 'P2P chat list ',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/p2p/chat-list')
},
{
    path: '/p2p/wallet-transaction',
    name: 'P2P wallet transaction list ',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/p2p/wallet-transaction')
},
{
    path: '/p2p/ordercreate',
    name: 'CreateOrder ',
    meta: { authRequired: true },
    component: () =>
        import('./views/crypto/p2p/create_order.vue')
},
{
    path: "/create/token",
    name: "tokenCreate",
    meta: { authRequired: true },
    component: () => import("./views/IEO_launchpad/tokenCreate.vue"),
},
{
    path: "/create/round",
    name: "roundCreate",
    meta: { authRequired: true },
    component: () => import("./views/IEO_launchpad/Round_Create.vue"),
},
{
    path: "/get/tokens",
    name: "token_get",
    meta: { authRequired: true },
    component: () => import("./views/IEO_launchpad/token_get.vue"),
},
{
    path: "/update/tokens/:id",
    name: "tokenUpdate",
    meta: { authRequired: true },
    component: () => import("./views/IEO_launchpad/TokenUpdate.vue"),
}

]